export const addressBook = require("../assets/address-book.svg");
export const caret = require("../assets/caret.svg");
export const check = require("../assets/check.svg");
export const checked = require("../assets/checked.svg");
export const filled = require("../assets/filled.svg");
export const img1 = require("../assets/image-1.svg");
export const img2 = require("../assets/image-2.svg");
export const img3 = require("../assets/image-3.svg");
export const img = require("../assets/image.svg");
export const key = require("../assets/key.svg");
export const lock = require("../assets/lock.svg");
export const phone = require("../assets/phone.svg");
export const signature = require("../assets/signature.svg");
export const superAdminImg = require("../assets/super-admin-img.png");
