'use strict';

const baseURL =
  'https://okydpim-36944-ruby.36944.dev.ap-southeast-1.aws.svc.builder.cafe';

Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.baseURL = baseURL;
