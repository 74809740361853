export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const logo = require("../assets/logo@2x.png");
export const dashboardIcon = require("../assets/dashboard@2x.png");
export const menuIcon = require("../assets/menu.png");
export const menuDownIcon = require("../assets/menu-down.png");
export const userIcon = require("../assets/user-with-bg.png");
export const viewIcon = require("../assets/view.png");
export const sortByIcon = require("../assets/sort-by.png");
export const logsIcon = require("../assets/logs.png");
export const logsActiveIcon = require("../assets/logs-white.png");
export const logsWighBgActiveIcon = require("../assets/logs-with-bg.png");
export const membersIcon = require("../assets/members-white.png");
export const membersActiveIcon = require("../assets/members.png");
export const lastLogIcon = require("../assets/last-log.png");
export const clearFilterIcon = require("../assets/clear-filter.png");
export const fillterPrimaryIcon = require("../assets/filter-primary.png");
export const closePrimaryIcon = require("../assets/close-primary.png");
export const timeWithBgIcon = require("../assets/time-with-bg.png");
export const teamManagementIcon = require("../assets/team-management.svg");
export const auditTrailIcon = require("../assets/audit-trail.svg");
export const accountSettingsIcon = require("../assets/account-settings.svg");
export const purchaseSettingsIcon = require("../assets/purchase-settings.svg");
export const logOutIcon = require("../assets/log-out.svg");
export const filtersIcon = require("../assets/filters-white.svg");
export const refreshIcon = require("../assets/refresh-primary.svg");
export const searchIcon = require("../assets/search-icon.svg");
export const addIcon = require("../assets/add-icon-auditTrial.svg");
